import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../controllers/user/user.controller';
import { MsalService } from '@azure/msal-angular';
import { silentRequest } from '../auth-config';
import { AuthenticationResult } from '@azure/msal-browser';
import { b2c_config } from 'src/environments/environment';


// https://medium.com/javarevisited/what-are-http-interceptors-and-how-to-use-them-in-angular-59fcb4efc235
// https://angular.io/api/common/http/HttpInterceptor

@Injectable({
    providedIn: 'root'
  })
  export class InterceptorService implements HttpInterceptor {

    constructor(
        private msalService: MsalService
        ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
      silentRequest.account = this.msalService.instance.getAllAccounts()[0];
      if (silentRequest.account) {
        if (silentRequest.account.idTokenClaims !== undefined) {
          silentRequest.authority = `https://${b2c_config.authority}/${b2c_config.directory}/` + silentRequest.account.idTokenClaims.acr;
        }
        silentRequest.forceRefresh = true;
        return this.msalService.acquireTokenSilent(silentRequest)
        .pipe(
            catchError((e) => next.handle(req)),
            switchMap((result: AuthenticationResult) => {
                const token = result.idToken;
                const requestClone = req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${token}`,
                  },
                });
                return next.handle(requestClone);
            })
        );
      } else {
        return next.handle(req);
      }
    }
  }
